body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

html,
body {
  height: 100%;
  overflow: hidden;
  touch-action: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Prevents tooltip flickering on react-google-charts pie charts */
svg > g > g:last-child {
  pointer-events: none;
}

/* Re-instates click events for final legend items on trend charts */
rect ~ g {
  pointer-events: auto !important;
}

input,
button,
submit {
  background: none;
  padding: 0;
}
