:root {
  --bottom-height: 8vh;
}

@media only screen and (min-width: 1000px) {
  :root {
    --bottom-height: 7vh;
  }
}

@media only screen and (min-width: 1244px) {
  :root {
    --bottom-height: 15vh;
  }
}

@media only screen and (min-width: 1350px) {
  :root {
    --bottom-height: 30vh;
  }
}

@media only screen and (min-width: 1400px) {
  :root {
    --bottom-height: 28vh;
  }
}

@media only screen and (min-width: 1600px) {
  :root {
    --bottom-height: 22vh;
  }
}

@media only screen and (min-width: 2200px) {
  :root {
    --bottom-height: 16vh;
  }
}

.deck-tooltip {
  position: absolute;
  background: white;
  margin: 10px;
  padding: 10px;
  z-index: 999999999 !important;
  font-size: 1.5vh !important;
  color: white !important;
}

#deckgl-overlay {
  position: absolute;
}

#deckgl-wrapper {
  cursor: auto !important;
  height: calc(100% - var(--bottom-height)) !important;
}

.loading_container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(100, 100, 100);
}

.loading_container p,
.loading_container span {
  font-size: 3vh;
  color: rgb(235, 235, 235);
  text-align: center;
}

.loading_container p {
  margin-top: calc(1rem + 2vh);
}

.ReactModal__Content {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
}

.loading_container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(100, 100, 100);
  overflow: hidden;
}

.loading_container p {
  margin-top: calc(1rem + 2vh);
  font-size: 3vh;
  color: rgb(235, 235, 235);
}

.nypd_arrest_map_container {
  max-height: 100vh;
  max-width: 100vw;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  transition: opacity 1s ease;
}

.footer_info_menu {
  height: 100vh;
  background: black;
}

div[id="tippy-1"],
div[id*="tippy-2"],
div[id*="tippy-3"],
div[id*="tippy-4"],
div[id*="tippy-5"],
div[id*="tippy-6"],
div[id*="tippy-7"],
div[id*="tippy-8"],
div[id*="tippy-9"] {
  position: absolute !important;
  z-index: 19 !important;
}

.mobile_landscape_block {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(125, 125, 125);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999 !important;
}

.mobile_landscape_block > svg {
  font-size: 25vh;
}

@media only screen and (max-width: 375px) {
  #deckgl-wrapper {
    height: 92.5vh !important;
  }
}

@media only screen and (min-width: 1224px) {
  .deck-tooltip {
    font-size: 0.8rem !important;
  }

  /* Prevent carousel transition on Safari desktops due to duplication of carousel items on slide issue */

  _::-webkit-full-page-media,
  _:future,
  :root .alice-carousel__stage {
    transition: none !important;
    -webkit-transition: none !important;
  }
}
