/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 46px;
  height: 40px;
  color: white;
  right: 0.5rem;
  bottom: 0;
  top: 0.5rem;
  margin: 0 auto;
  z-index: 9999;
  outline: none;
}

.bm-burger-button:focus {
  outline: 0;
}

.info_circle_container {
  position: absolute;
  right: 5rem;
  top: 0.8rem;
}

.info_button {
  width: 36px;
  height: 30px;
  z-index: 9999;
  cursor: pointer;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #000;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  max-height: 100vh;
  overflow: auto;
}

/* General sidebar styles */
.bm-menu {
  background: rgb(225, 225, 225);
  padding: 2.5em 0 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  max-width: 100vw;
}

@media only screen and (max-width: 375px) {
  .info_circle_container {
    right: 4rem;
  }

  .info_button {
    width: 30px;
    height: 24px;
  }

  .bm-burger-button {
    width: 40px;
    height: 34px;
  }
}

@media only screen and (min-width: 1224px) {
  /* Position and sizing of burger button */
  .bm-burger-button {
    top: 1rem;
  }

  .bm-menu-wrap {
    width: 30vw !important;
  }

  .info_circle_container {
    top: 3vh;
  }
}

@media only screen and (min-width: 1400px) {
  .info_circle_container {
    top: 2.5vh;
  }
}

@media only screen and (min-width: 1600px) {
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 20vw !important;
  }
}

@media only screen and (min-width: 1900px) and (max-width: 2199px) {
  .info_circle_container {
    position: absolute;
    top: 1.3rem !important;
  }
}

@media only screen and (min-width: 2000px) {
  .info_circle_container {
    top: 1.5vh;
  }
}
